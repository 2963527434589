import { axiosAuthorized } from "../../api/config"

export const getXRayBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/xraybanner/get_xraybanner`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addXRayBanner = async (initialState, xray_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      xray_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/xraybanner/update_xraybanner/${id}`
      : `/xraybanner/add_xraybanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteXRayBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/xraybanner/delete_xraybanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}