import { axiosAuthorized } from "../../api/config";


export const getTubes = async () => {
    try {
        const repsonse = await axiosAuthorized.get(`/tube/get_tube`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}
export const addTube = async (initialState, tube_image, id = null) => {
    try {
        const reqestData = {
            ...initialState,
            tube_image
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        }
        const url = id ? `/tube/update_tube/${id}` : `/tube/add_tube`
        const repsonse = await axiosAuthorized[id ? "put" : "post"](url, reqestData, requestConfig);
        return repsonse;
    } catch (error) {
        throw error;
    }
}

export const deleteTube = async (id) => {
    try {
        const repsonse = await axiosAuthorized.delete(`/tube/delete_tube/${id}`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}