import { axiosAuthorized } from "../../api/config"

export const getBanner = async () => {
    try {
      const response = await axiosAuthorized.get(`/labbanner/get_labbanner`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addBanner = async (initialState, bullet_points, doctors_image, id = null) => {
    try {
        const requestData = {
          ...initialState,
          bullet_points,
          doctors_image  
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = id
          ? `/labbanner/update_labbanner/${id}`
          : `/labbanner/add_labbanner`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBanner = async (id) => {
    try {
      const response = await axiosAuthorized.delete(`/labbanner/delete_labbanner/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}