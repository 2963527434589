import { useRef } from "react";
import "./ImageInput.css";
import { AiOutlineCloudUpload } from "react-icons/ai";

const ImageInput = ({ setImage, imageName, dimension = "" }) => {
  const fileInputRef = useRef(null);
  const handleImageInput = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
    event.target.value = "";
  };
  const handleImageDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    setImage(selectedFile);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div
      className="image-input-container flex-col gap-2"
      onDrop={handleImageDrop}
      onDragOver={handleDragOver}
    >
      <div className="image-input">
        <div className="upload-icon-container text-white">{imageName}</div>
        <div className="image-input-content">
          <button
            type="button"
            className="image-input-button border-primary gap-2"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload{" "}
            <AiOutlineCloudUpload
              style={{ width: "20px", height: "20px", color: "white" }}
            />
          </button>
        </div>
      </div>
      {dimension && (
        <span className="text-red-600 text-sm font-semibold">{dimension}</span>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageInput}
      />
    </div>
  );
};

export default ImageInput;
