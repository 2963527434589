import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addHealthAndWellnessBanner } from "../../utils/homeUtils/healthAndWellnessBannerUtils";

const HealthAndWellnessBannerModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.health_banner_image || null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image) return setErr({ global: "Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addHealthAndWellnessBanner(image, editData?._id)
      .then((res) => {
        setDatasFn();
        setImage(null);
        setOpen(false);
        toast(`Banner image ${editData ? "edited" : "added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Banner image  ${editData ? "edit failed" : "add failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-full  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto h-fit border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Banner Image"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            dimension="1920x800"
            imageName={"Banner Image"}
            setImage={setImage}
          />
          <ShowImage image={image} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setImage(null);
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthAndWellnessBannerModal;
