import React from "react";
import { updateStatusOfFlebo } from "../../utils/appointmentUtils/appointmentUtils";
import { toast } from "react-toastify";

const FleboStatus = ({ data, setAppointmentsFn }) => {
  const getStatus = () => {
    if (
      data?.is_accepted === true &&
      data?.deposit_collecting_center === null &&
      data?.sample_collected === null &&
      data?.fablo_reached === null
    ) {
      return { text: "Phlebo Started", color: "text-green-600" };
    }
    if (data?.deposit_collecting_center === true) {
      if (data?.is_deposit_in_center_accepted === false) {
        return { text: "Deposit In Center Rejected", color: "text-red-600" };
      } else if (data?.is_deposit_in_center_accepted === true) {
        return { text: "Deposit In Center Accepted", color: "text-green-600" };
      }
      return { text: "Deposit In Center", color: "text-green-600" };
    }
    if (data?.sample_collected === true) {
      if (data?.is_sample_collected_accepted === false) {
        return { text: "Sample Collected Rejected", color: "text-red-600" };
      } else if (data?.is_sample_collected_accepted === true) {
        return { text: "Sample Collected Accepted", color: "text-yellow-600" };
      }
      return { text: "Sample Collected", color: "text-yellow-600" };
    }
    if (data?.fablo_reached === true) {
      return { text: "Reached", color: "text-blue-600" };
    }
    return { text: "Not Started Yet!", color: "text-red-600" };
  };

  const status = getStatus();

  const updateFn = (updateData) => {
    updateStatusOfFlebo(updateData, data?._id)
      .then((res) => {
        setAppointmentsFn();
        toast(`Phlebo Status Updated`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast(`Test Status Update Failed`, {
          type: "success",
          autoClose: 3000,
        });
      });
  };

  const handleSampleCollected = (accepted) => {
    const updatedData = {
      is_sample_collected_accepted: data.sample_collected
        ? accepted
        : data.is_sample_collected_accepted,
    };
    updateFn(updatedData);
  };
  const handleDepositInCenter = (accepted) => {
    const updatedData = {
      is_deposit_in_center_accepted: data.deposit_collecting_center
        ? accepted
        : data.is_deposit_in_center_accepted,
    };
    updateFn(updatedData);
  };

  const renderButtons = () => {

    if (
      data?.sample_collected === true &&
      data?.is_sample_collected_accepted === null
    ) {
      return (
        <div className="text-sm text-white flex flex-col gap-2 items-center">
          <span className="text-gray-900">Update Sample Collected Status</span>
          <div className="flex gap-2 capitalize">
            <button
              onClick={() => handleSampleCollected(false)}
              className="px-2 py-1 text-center bg-red-500 rounded-full"
            >
              Reject
            </button>
            <button
              onClick={() => handleSampleCollected(true)}
              className="px-2 py-1 text-center bg-green-500 rounded-full"
            >
              Accept
            </button>
          </div>
        </div>
      );
    }

    if (
      data?.deposit_collecting_center === true &&
      data?.is_deposit_in_center_accepted === null
    ) {
      return (
        <div className="text-sm text-white flex flex-col gap-2 items-center">
          <span className="text-gray-900">Update Deposit In Center Status</span>
          <div className="flex gap-2 capitalize">
            <button
              onClick={() => handleDepositInCenter(false)}
              className="px-2 py-1 text-center bg-red-500 rounded-full"
            >
              Reject
            </button>
            <button
              onClick={() => handleDepositInCenter(true)}
              className="px-2 py-1 text-center bg-green-500 rounded-full"
            >
              Accept
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`text-sm text-gray-900 ${status.color} flex flex-col gap-2 items-center`}
    >
      <span>{status.text}</span>
      {renderButtons()}
    </div>
  );
};

export default FleboStatus;
