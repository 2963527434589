import React, { useState } from "react";
import {
  ImageInput,
  NumberInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import { addVitalOrganCategory } from "../../utils/categoriesUtils/vitalOrganCategoryUtils";
import { toast } from "react-toastify";

const VitalOrganCategoryModal = ({
  setOpen,
  editData = null,
  setEditData,
  setCategoriesFn,
  user,
}) => {
  const initailsState = {
    vital_category_name: editData?.vital_category_name || "",
    vital_category_description: editData?.vital_category_description || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
    vital_category_price: editData?.vital_category_price || "",
  };
  const [vital_category_icon, setCategoryImage] = useState(
    editData?.vital_category_icon || null
  );
  const [formData, handleFormData, clearForm] = useForm(initailsState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.vital_category_name.trim().length) {
      setErr({ global: "Invalid Category Name" });
      return;
    }
    if (!vital_category_icon) {
      setErr({ global: "Please Select Image" });
      return;
    }
    setLoading(true);
    addVitalOrganCategory(formData, vital_category_icon, editData?._id)
      .then((res) => {
        setCategoriesFn();
        setEditData(null);
        setCategoryImage(null);
        clearForm();
        setOpen(false);
        toast(`Vital Organ Category ${editData ? `Edited` : `Added`}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast(`Vital Organ Category ${editData ? `Edit Failed` : `Add Failed`}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Vital Organ Category" : "Add New Vital Organ Category"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name="Category"
            formName={"vital_category_name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            name="Price"
            formName={"vital_category_price"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name="Description"
            formName={"vital_category_description"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            hide={true}
            name="Meta Name"
            formName={"meta_name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name="Meta Description"
            formName={"meta_description"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>

        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={vital_category_icon} setImage={setCategoryImage} />
          <ImageInput
            imageName={"Category Image"}
            setImage={setCategoryImage}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalOrganCategoryModal;
