import { axiosAuthorized } from "../../api/config"

export const getCertificateBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/certificatebanner/get_certificatebanner`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCertificateBanner = async (initialState, certificate_banner_icon, id = null) => {
  try {
    const requestData = new FormData();
    Object.entries(initialState).forEach(([key, value]) => {
      requestData.append(key, value);
    });
    certificate_banner_icon.forEach((image) => {
      requestData.append("certificate_banner_icon", image);
    });
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/certificatebanner/update_certificatebanner/${id}`
      : `/certificatebanner/add_certificatebanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCertificateBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/certificatebanner/delete_certificatebanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}