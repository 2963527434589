import React from "react";

const TestStatus = ({ data }) => {
  const getStatus = () => {
    if (data?.status === "order-placed") {
      return { color: "text-blue-600" };
    }
    if (data?.status === "sample-testing") {
      return { color: "text-yellow-600" };
    }
    return { color: "text-green-600" };
  };

  const status = getStatus();

  return (
    <div className={`text-sm text-gray-900 capitalize ${status.color}`}>{data?.status}</div>
  );
};

export default TestStatus;
