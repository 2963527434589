import { axiosAuthorized } from "../../api/config";

export const getHomeAppointments = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethomelabbooking`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getHospitalAppointments = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethospitallabooking`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAppointment = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labbookinghospital/delete_labbookinghospital/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateStatus = async (status, upload_lab_report, appointmetnId) => {
  try {
    const requestData = upload_lab_report ? { status, upload_lab_report } : { status };
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const response = await axiosAuthorized.put(`/labbookinghospital/update_labbookinghospital/${appointmetnId}`, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateStatusOfFlebo = async (data, id) => {
  try {
    const requestData = { ...data }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosAuthorized.put(`/labbookinghospital/update_labbookinghospital/${id}`, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateFlebo = async (fablo_region, appointmetnId) => {
  try {

    const requestData = { fablo_region }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosAuthorized.put(`/labbookinghospital/update_labbookinghospital/${appointmetnId}`, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}