import React, { useState, useEffect } from 'react';
import { TiArrowLeft } from 'react-icons/ti';
import PasswordInput from '../../Common/FormComponents/PasswordInput/PasswordInput';
import { submitNewPassword } from '../../../utils/loginUtils/loginUtils';

const NewPasswordField = ({
  loginData,
  handleLoginData,
  setErr,
  clearForm,
  setForgot,
  setEmailVerfied,
  setOtpVerified,
  loading,
  setLoading,
  err,
  handleResendOtp,
}) => {
  const [changed, setChanged] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleSubmit = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    if (!/^.{8,}$/.test(loginData.newPassword)) {
      setErr({ global: "Invalid Password" });
      return;
    }
    setLoading(true);
    submitNewPassword(loginData.token, loginData.newPassword, setErr)
      .then((res) => {
        setErr({ global: "" });
        setChanged("Password Changed");
        setTimeout(() => {
          clearForm();
          setForgot(false);
          setEmailVerfied(false);
          setOtpVerified(false);
        }, 1000);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setSessionExpired(true); // Session expired
    }
  }, [timeLeft]);

  const handleResend = () => {
    if (sessionExpired) {
      handleResendOtp(); 
      setTimeLeft(120);
      setSessionExpired(false); 
      setOtpVerified(false);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex w-full h-full justify-center items-center"
    >
      <div className="flex w-4/5 lg:w-1/2 h-fit flex-col gap-5 px-10 lg:px-0">
        <PasswordInput
          data={loginData}
          handleInput={handleLoginData}
          name="Password"
          formName={"newPassword"}
          setError={setErr}
          labelColor="text-black"
        />

        {/* Back Button */}
        <span
          onClick={() => {
            setErr({ global: "" });
            setForgot(false);
            setEmailVerfied(false);
            setOtpVerified(false);
          }}
          style={{
            color: "transparent",
            WebkitTextFillColor: "transparent",
            background:
              "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
          }}
          className="w-full flex gap-1 items-center justify-end text-sm cursor-pointer "
        >
          <TiArrowLeft color="black" /> back
        </span>

        {/* Error Message */}
        {err.global && (
          <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
            {err.global}
          </div>
        )}

        {/* Password Changed Message */}
        {changed.length > 0 && (
          <div className="flex w-full h-fit text-green-600 justify-center items-center font-semibold text-sm">
            {changed}
          </div>
        )}

        {/* Timer and Session Expiry */}
        {sessionExpired ? (
          <div className="flex w-full justify-center items-center text-red-600 text-sm font-semibold">
            Session expired! Please resend the OTP.
          </div>
        ) : (
          <div className="flex w-full justify-center items-center text-sm">
            Session expires in {Math.floor(timeLeft / 60)}:
            {String(timeLeft % 60).padStart(2, '0')}
          </div>
        )}

        {/* Resend Button */}
        {sessionExpired && (
          <button
            onClick={handleResend}
            className="text-blue-500 hover:text-blue-700"
          >
            Resend OTP
          </button>
        )}

        {/* Submit Button */}
        <button
          disabled={loading || sessionExpired} // Disable if loading or session expired
          onClick={(e) => handleSubmit(e)}
          style={{
            background:
              "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          className="w-full h-fit py-1 justify-center items-center px-2  text-white rounded-md"
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default NewPasswordField;
