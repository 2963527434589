import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import { BiTestTube } from "react-icons/bi";
import TestsTable from "./TestsTable";
import {
  // getFrequentlyBookedTests,
  getTests, getTopBookedTests
} from "../../utils/testsUtils/testsUtils";
import TestModal from "./TestModal";
import { useAuth } from "../../context/useAuth";
import FrequentlyBookedTests from "./FrequentlyBookeTests";
// import FrequentlyBookedTestsModal from "./FrequentlyBookedTestsModal";
import TopBookedTestsModal from "./TopBookedTestsModal";

const TestMainSection = () => {
  const user = useAuth().authState.user;
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tests, setTests] = useState([]);
  // const [frequentlyBookedTestsData, setFrequentlyBookedTestsData] = useState(null);
  const [topBookedTestsData, setTopBookedTestsData] = useState(null);
  // const [openFrequentlyBookedTests, setOpenFrequentlyBookedTets] = useState(false);
  const [openTopBookedTests, setOpenTopBookedTets] = useState(false);
  // const [frequentlyBookedTests, setFrequentlyBookedTests] = useState(null);
  const [topBookedTests, setTopBookedTests] = useState(null);
  // const setFrequentlyBookedTestsFn = useCallback(() => {
  //   getFrequentlyBookedTests()
  //     .then((res) => setFrequentlyBookedTests(res?.data?.data?.[0]))
  //     .catch((err) => console.log(err));
  // }, []);
  const setTopBookedTestsFn = useCallback(() => {
    getTopBookedTests()
      .then((res) => setTopBookedTests(res?.data?.data?.[0]))
      .catch((err) => console.log(err));
  }, []);
  const setTestsFn = useCallback(() => {
    getTests(user?._id)
      .then((res) => {
        setTests(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [user?._id]);
  useEffect(() => {
    setTestsFn();
    // setFrequentlyBookedTestsFn();
    setTopBookedTestsFn();
  }, [ setTestsFn, setTopBookedTestsFn]);
  const [selected, setSelected] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = tests?.filter((test) => {
      const nameMatch = test?.test_name
        .toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const tIdMatch = test?.test_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const departmentMatch = test?.department
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const categoryMatch = test?.category?.category_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vitalOrganCategoryMatch = test?.category?.vital_category_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return (
        nameMatch ||
        tIdMatch ||
        departmentMatch ||
        categoryMatch ||
        vitalOrganCategoryMatch
      );
    });
    setFilteredData(filteredData);
  }, [tests, searchQuery]);
  return (
    <>
      {open && (
        <TestModal
          user={user}
          setEditData={setEditData}
          setOpen={setOpen}
          setTestsFn={setTestsFn}
          editData={editData}
        />
      )}
      {/* {openFrequentlyBookedTests && (
        <FrequentlyBookedTestsModal
          user={user}
          setEditData={setFrequentlyBookedTestsData}
          setOpen={setOpenFrequentlyBookedTets}
          setTestsFn={setFrequentlyBookedTestsFn}
          editData={frequentlyBookedTestsData}
        />
      )} */}
      {openTopBookedTests && (
        <TopBookedTestsModal
          user={user}
          setEditData={setTopBookedTestsData}
          setOpen={setOpenTopBookedTets}
          setTestsFn={setTopBookedTestsFn}
          editData={topBookedTestsData}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Tests</div>
        {selected === 1 && <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name, Id, Department, Category"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>}
      </div>
      <div className="flex gap-2.5 flex-wrap mt-3">
        <button
          onClick={() => setSelected(1)}
          className={`flex px-3 border border-primary py-1 rounded-md ${selected === 1 ? `bg-primary text-white` : `bg-white text-primary`}`}
        >
          All
        </button>
        {/* <button
          onClick={() => setSelected(2)}
          className={`flex px-3 border border-primary py-1 rounded-md ${selected === 2 ? `bg-primary text-white` : `bg-white text-primary`}`}
        >
          Frequently Booked
        </button> */}
        <button
          onClick={() => setSelected(2)}
          className={`flex px-3 border border-primary py-1 rounded-md ${selected === 2 ? `bg-primary text-white` : `bg-white text-primary`}`}
        >
          Top Booked
        </button>
      </div>

      {selected === 1 && <> <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">Tests</div>
        <div className="flex gap-2">
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className=" text-white   text-sm rounded-md flex gap-2 p-2  items-center"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <BiTestTube />
            </span>
            <span className="flex gap-1">Test</span>
          </button>
        </div>
      </div>
        {filteredData?.length > 0 ? (
          <TestsTable
            datas={filteredData}
            setEditData={setEditData}
            setOpen={setOpen}
            setTestFn={setTestsFn}
          />
        ) : (
          <div className="flex w-full justify-center items-center mt-10">
            <NoDataFound data={"tests"} />
          </div>
        )}</>}
      {/* {selected === 2 && <><div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">
          Frequently Booked Tests
        </div>
        <div className="flex gap-2">
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className=" text-white   text-sm rounded-md flex gap-2 p-2  items-center"
            onClick={() => {
              setOpenFrequentlyBookedTets(true);
              setFrequentlyBookedTestsData(frequentlyBookedTests);
            }}
          >
            <span className="flex gap-1">Update</span>
          </button>
        </div>
      </div>
        {frequentlyBookedTests?.frequently_booked_test?.length > 0 ? (
          <FrequentlyBookedTests
            datas={frequentlyBookedTests?.frequently_booked_test}
            setEditData={setEditData}
            setOpen={setOpen}
            setTestFn={setTestsFn}
          />
        ) : (
          <div className="flex w-full justify-center items-center mt-10">
            <NoDataFound data={"tests"} />
          </div>
        )}
      </>
      } */}
      {selected === 2 && <><div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">
          Top Booked Tests
        </div>
        <div className="flex gap-2">
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className=" text-white   text-sm rounded-md flex gap-2 p-2  items-center"
            onClick={() => {
              setOpenTopBookedTets(true);
              setTopBookedTestsData(topBookedTests);
            }}
          >
            <span className="flex gap-1">Update</span>
          </button>
        </div>
      </div>
        {topBookedTests?.top_booked_test?.length > 0 ? (
          <FrequentlyBookedTests
            datas={topBookedTests?.top_booked_test}
            setEditData={setEditData}
            setOpen={setOpen}
            setTestFn={setTestsFn}
          />
        ) : (
          <div className="flex w-full justify-center items-center mt-10">
            <NoDataFound data={"tests"} />
          </div>
        )}
      </>}
    </>
  );
};

export default TestMainSection;
