export const validatePackageForm = (initialState,descripiton, tests, image, setErr) =>{
    if (!initialState.health_package_name.trim().length) {
        setErr({ global: "Invalid Package Name" });
        return false;
  }
  if (initialState.service === "Select Service") {
    setErr({ global: "Please Select Service" });
    return false;
  }

  if (initialState.gender === "Select Gender") {
    setErr({ global: "Please Select Gender" });
    return false;
  }
    if (!descripiton.replace(/<[^>]*>/g, "").trim().length) {
        setErr({ global: "Invalid Description" });
      return false;
    }
  if (!initialState.time_required.trim().length) {
    setErr({ global: "Invalid Time Required" });
    return false;
  }
    if (!tests.length) {
        setErr({ global: "Add At Least One Test" });
        return false;
    }
    if (!image) {
        setErr({ global: "Please Select Image" });
        return false;
    }
    return true;
}