import React, { useCallback, useEffect, useState } from "react";
import { TextInput } from "../Common/FormComponents";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import { addTopBookedTest, getTests } from "../../utils/testsUtils/testsUtils";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";

const TopBookedTestsModal = ({
    setOpen,
    editData = null,
    setEditData,
    setTestsFn,
    user,
}) => {
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const setTopBookedTestsFn = useCallback(() => {
        getTests()
            .then((res) => setTests(res.data.data))
            .catch((error) => console.error(error));
    }, []);
    useEffect(() => {
        setTopBookedTestsFn();
    }, [setTopBookedTestsFn]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        const filteredData = tests?.filter((test) => {
            const nameMatch = test?.test_name
                ?.toLowerCase()
                ?.includes(searchQuery.toLowerCase());
            const idMatch = test?.test_id
                ?.toLowerCase()
                ?.includes(searchQuery.toLowerCase());
            return nameMatch || idMatch;
        });
        setFilteredData(filteredData);
    }, [tests, searchQuery]);
    const [top_booked_test, setTestsData] = useState(editData?.top_booked_test || []);
    const handleTestData = (action, idx = 0, item = null) => {
        if (action === "add") {
            if (!item) return;
            const exist = top_booked_test.find(data => data?._id === item?._id);
            if (exist) return;
            setSelected(item);
            setTestsData([
                ...top_booked_test,
                item,
            ]);
        } else if (action === "remove") {
            setTestsData(top_booked_test.filter((_, index) => index !== idx));
        }
    };
    const initialState = {
        title: editData?.title || "",
    };
    const [formData, handleFromData] = useForm(initialState);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.title) return setErr({ global: "Invalid Title" });
        setLoading(true);
        addTopBookedTest(formData, top_booked_test, editData?._id)
            .then((res) => {
                setTestsFn();
                setOpen(false);
                toast(`Top Booked Tests Updated`, {
                    type: "success",
                    autoClose: 3000,
                });
            })
            .catch((err) => {
                console.log(err);
                toast(`Top Booked Tests Update Failed`, {
                    type: "error",
                    autoClose: 3000,
                });
            })
            .finally(() => setLoading(false));
    };
    return (
        <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
            <div
                style={{
                    background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
                }}
                className="flex w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
            >
                <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
                    <span>Top Booked Tests</span>
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <GrFormClose fontSize={16} />
                    </span>
                </div>
                <div className="flex flex-col md:flex-row w-full h-fit justify-between gap-5">
                    <TextInput
                        name="Title"
                        formName={"title"}
                        data={formData}
                        handleInput={handleFromData}
                        setError={setErr}
                    />
                </div>
                {top_booked_test?.length > 0 && (
                    <div className="flex w-full gap-1 flex-wrap">
                        {top_booked_test?.map((item, idx) => (
                            <div
                                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                                key={idx}
                            >
                                <div className="flex items-center gap-1 text-white">
                                    <div className={`flex w-7 h-7`}>
                                        <img
                                            src={
                                                item?.test_pic instanceof File
                                                    ? URL.createObjectURL(item?.test_pic)
                                                    : item?.test_pic
                                            }
                                            alt={"service_image"}
                                            className={`rounded-full ${item?.test_pic ? "flex" : "hidden"
                                                }`}
                                        />
                                    </div>
                                    <span>{item?.test_name}</span>
                                </div>
                                <button
                                    onClick={() => {
                                        handleTestData("remove", idx);
                                    }}
                                >
                                    <AiOutlineCloseCircle fontSize={14} color="red" />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <div className="relative flex flex-col gap-0.5">
                    <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
                        <IoSearchOutline fontSize={23} />
                    </span>
                    <label
                        htmlFor={"List Of Tests"}
                        className="text-[14px] text-shadow-black text-white"
                    >
                        {"List Of Tests"}
                    </label>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
                        placeholder="Search By Name, ID"
                    />
                </div>
                <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[400px] overflow-y-scroll px-5 m-3 ">
                    {filteredData?.length > 0 ? (
                        filteredData?.map((item) => (
                            <div
                                key={item._id}
                                onClick={() => {
                                    handleTestData("add", null, item);
                                }}
                                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${selected?._id === item?._id
                                    ? `bg-white text-primary border-primary`
                                    : `text-white`
                                    }`}
                            >
                                {`${item?.test_name} - ${item?.test_id}`}
                            </div>
                        ))
                    ) : (
                        <div className="flex h-full w-full justify-center items-center text-white">
                            No Tests Found!
                        </div>
                    )}
                </div>
                {err.global && (
                    <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
                        {err.global}
                    </div>
                )}
                <div className="flex w-full h-fit justify-between gap-5">
                    <div className=" flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                            onClick={() => {
                                setOpen(false);
                                setEditData(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
                            disabled={loading}
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBookedTestsModal;
