import React from "react";
import FlebMainSection from "../../components/FleboComponents/FlebMainSection";

const Flebo = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <FlebMainSection/>
    </div>
  );
};

export default Flebo;
