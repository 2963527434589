import React from "react";
import TubesMainSection from "../../components/TubesComponents/TubesMainSection";
const Tubes = () => {
    return (
        <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
            <TubesMainSection />
        </div>
    );
};

export default Tubes;
