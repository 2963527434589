import React from "react";
import "./ColorInput.css";

const ColorInput = ({
  name = "",
  formName,
  data,
  handleInput,
  setError,
  hide = false,
  labelColor = "text-white",
}) => {
  return (
    <div className="label-and-field">
      {name.length > 0 && (
        <label htmlFor={formName} className={`label ${labelColor}`}>
          {name}
        </label>
      )}
      <div className="input-container" onClick={() => setError({ global: "" })}>
        <input
          type="color"
          name={formName}
          id={formName}
          value={data[formName] || "#000000"}
          onChange={handleInput}
        />
      </div>
    </div>
  );
};

export default ColorInput;
