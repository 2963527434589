import { axiosAuthorized } from "../../api/config"

export const getNeedHelpWithBookingBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/labhelpus/get_labhelpus`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addNeedHelpWithBookingBanner = async (initialState, help_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      help_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/labhelpus/update_labhelpus/${id}`
      : `/labhelpus/add_labhelpus`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteNeedHelpWithBookingBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labhelpus/delete_labhelpus/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}