import React from "react";
import Region from "./Region";
import Flebo from "./Flebo";

const FlebMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex w-full h-fit text-xl font-semibold">
          Region & Phlebo
        </div>
        <div className="flex flex-col w-full mt-5 gap-5">
          <Region />
          <Flebo />
        </div>
      </div>
    </>
  );
};

export default FlebMainSection;
