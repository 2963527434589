import React from 'react'
import { LoginForm } from '../../components/LoginComponents'

const Login = () => {
  return (
    <div className="flex w-screen h-screen flex-col">
      <LoginForm/>
    </div>
  )
}

export default Login