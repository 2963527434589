import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import { BsPlus } from "react-icons/bs";
import { useAuth } from "../../context/useAuth";
import TubeModal from "./TubesModal";
import TubesTable from "./TubesTable";
import { getTubes } from "../../utils/tubesUtils/tubesUtils";

const TubesMainSection = () => {
    const [open, setOpen] = useState(false);
    const user = useAuth().authState.user;
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [tubes, setTubes] = useState([]);
    const [editData, setEditData] = useState(null);
    useEffect(() => {
        const filteredData = tubes?.filter((tube) => {
            const nameMatch = tube?.tube_name?.toLowerCase()
                ?.includes(searchQuery?.toLowerCase());
            return nameMatch;
        });
        setFilteredData(filteredData);
    }, [tubes, searchQuery]);
    const setTubesFn = useCallback(() => {
        getTubes(user?._id)
            .then((res) => {
                setTubes(res.data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
        setTubesFn();
    }, [setTubesFn]);
    return (
        <>
            {open &&
                <>
                    <TubeModal
                        setDatasFn={setTubesFn}
                        setEditData={setEditData}
                        setOpen={setOpen}
                        editData={editData}
                    />
                </>
            }
            <div className="flex justify-between items-center">
                <div className="flex font-semibold text-lg">Tubes</div>
                <div className="flex">
                    <div className="flex">
                        <SearchInput
                            placeholder={"Search by Name"}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                    </div>
                </div>
            </div>
            <div className="flex  w-full h-full mt-5 justify-end items-center">
                <button
                    style={{
                        background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
                    }}
                    className=" text-white   text-sm rounded-md flex gap-2 p-2 items-center flex-shrink-0"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <span>
                        <BsPlus fontSize={18} />
                    </span>
                    <span className="flex gap-1 ">{`Tube`}</span>
                </button>
            </div>
            {filteredData?.length > 0 ? (
                <TubesTable
                    datas={filteredData}
                    setEditData={setEditData}
                    setOpen={setOpen}
                    setTubesFn={setTubesFn}
                 />
            ) : (
                <div className="flex w-full justify-center items-center mt-10">
                    <NoDataFound data={"tubes"} />
                </div>
            )}
        </>
    );
};

export default TubesMainSection;
