import React, { useMemo } from "react";
import { Table } from "../Common";

const FrequentlyBookedTests = ({ datas }) => {
  const headings = useMemo(
    () => [
      "Test",
      "T-ID",
      "Gender",
      "Price",
      "Discount",
      "Total",
    ],
    []
  );
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={data?.test_pic}
                  alt="test_image"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {data?.test_name}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.test_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.gender}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.price}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.discount}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.total_price}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default FrequentlyBookedTests;
