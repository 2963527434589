import { axiosAuthorized } from "../../api/config"

export const getBookLabTestWithUs = async () => {
  try {
    const response = await axiosAuthorized.get(`/getbooklabtestwithus`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBookLabTestWithUs = async (initialState, lab_icon_image, id = null) => {
  try {
    const requestData = {
      name: "Book Lab Test With Us",
      ...initialState,
      lab_icon_image
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/labicon/update_labicon/${id}`
      : `/labicon/add_labicon`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteBookLabTestWithUs = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labicon/delete_labicon/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}