import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Appointments, Categories, Flebo, Home, Login, Packages, Tests, Tubes} from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Tests />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/tubes" element={<Tubes />} />
        <Route path="/phlebo" element={<Flebo />} />
        <Route path='/home' element={<Home />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/categories" element={<Categories />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
