import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  NumberInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { useEffect } from "react";
import { useCallback } from "react";
import { addFlebo, getRegions } from "../../utils/fleboUtils/fleboUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";

const FleboModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.fablo_profile || null);
  const [loading, setLoading] = useState(false);
  const initialsState = {
    name: editData?.name || "",
    designation: editData?.designation  || "",
    email_id: editData?.email_id || "",
    phone_number: editData?.phone_number || "",
  };
  const [regions, setRegions] = useState([]);
  const [lab_region, setFabloRegions] = useState(
    editData?.lab_region || []
  );
  const [selected, setSelected] = useState(null);  
  const setRegionsFn = useCallback(() => {
      getRegions()
        .then((res) => {
          setRegions(res?.data?.data || []);
        })
        .catch((err) => { console.error(err); });
    }, []);
    useEffect(() => {
      setRegionsFn();
    }, [setRegionsFn]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredRegions, setFilteredRegions] = useState([]);
    useEffect(() => {
      const filteredData = regions?.filter((region) => {
        const nameMatch = region?.lag_region_name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const rangeMatch = region?.region_range
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        return nameMatch || rangeMatch;
      });
      setFilteredRegions(filteredData);
    }, [regions, searchQuery]);
    const handleRegions = (action, idx = 0, item = null) => {
      if (action === "add") {
        if (!item) return;
        const exist = lab_region.find((data) => data?._id === item?._id);
        if (exist) return;
        setSelected(item);
        setFabloRegions([...lab_region, item]);
      } else if (action === "remove") {
        setFabloRegions(lab_region.filter((_, index) => index !== idx));
      }
    };
  const [formData, handleFormData, clearForm] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name.trim().length)
      return setErr({ global: "Invalid Name" });
    if (!formData.designation.trim().length)
      return setErr({ global: "Invalid Designation" });
    if (!formData.email_id.trim().length)
      return setErr({ global: "Invalid Email" });
    if (!formData.phone_number.toString().trim().length)
      return setErr({ global: "Invalid Phone" });
    if (!image) return setErr({ global: "Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addFlebo(formData, lab_region, image, editData?._id)
      .then((res) => {
        setDatasFn();
        setImage(null);
        setOpen(false);
        clearForm();
        setEditData(null);
        toast(`Phlebo ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Phlebo ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-full max-w-lg overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Phlebo"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        {lab_region?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {lab_region?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.lag_region_icon instanceof File
                          ? URL.createObjectURL(item?.lag_region_icon)
                          : item?.lag_region_icon
                      }
                      alt={"region_icon"}
                      className={`rounded-full ${
                        item?.lag_region_icon ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.lag_region_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleRegions("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of General Categories"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of General Categories"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name, Range"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredRegions.length > 0 ? (
            filteredRegions.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleRegions("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                  selected?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                }`}
              >
                {`${item?.lag_region_name}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Regions Found!
            </div>
          )}
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextInput
            data={formData}
            formName={"name"}
            name="Name"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"designation"}
            name="Designation"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextInput
            data={formData}
            formName={"email_id"}
            name="Email"
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            data={formData}
            formName={"phone_number"}
            name="Phone"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput imageName={"Fablo Profile"} setImage={setImage} />
          <ShowImage image={image} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleboModal;
