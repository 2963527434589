import React, { useState } from "react";
import { GoEye, GoEyeClosed } from "react-icons/go";
import "./PasswordInput.css";

const PasswordInput = ({ name = "", formName, data, handleInput, setError, labelColor="text-white" }) => {
    const [isShow, setIsShow] = useState(false);
  return (
    <div className="lable-and-filed">
      {name.length > 0 && (
        <label
          htmlFor={name}
          className={`text-[14px] text-shadow-black ${labelColor}`}
        >
          {name} <span className="text-red-600">*</span>
        </label>
      )}
      <div
        className="input-coantiner relative items-center"
        onClick={() => setError({ global: "" })}
      >
        <input
          type={isShow ? `text` : `password`}
          name={formName}
          id={formName}
          value={data[formName]}
          onChange={handleInput}
          placeholder={name}
          className="input"
        />
        <button
          type="button"
          onClick={() => {
            setIsShow(!isShow);
          }}
          className="flex absolute right-2"
        >
          {isShow ? <GoEye /> : <GoEyeClosed />}
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
