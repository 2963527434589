import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Table } from "../Common";
import { TbUserEdit } from "react-icons/tb";
import { deleteGeneralCategory } from "../../utils/categoriesUtils/generalCategoryUtils";
import { deleteVitalOrganCategory } from "../../utils/categoriesUtils/vitalOrganCategoryUtils";

const CategoriesTable = ({ datas, setOpen, setEditData, setCategoriesFn, selected, setCategory }) => {
  const headings = useMemo(
    () => [
        "Category",
        "Tests",
        "Edit",
        "Delete"
    ],
    []
  );
  const delCategory = (categoryId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    selected === "General" ? 
    deleteGeneralCategory(categoryId)
      .then((res) => setCategoriesFn())
        .catch((err) => console.log(err))
    : deleteVitalOrganCategory(categoryId)
      .then((res) => setCategoriesFn())
      .catch((err) => console.log(err))
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={
                    selected === "General"
                      ? data?.category_icon
                      : data?.vital_category_icon || ""
                  }
                  alt="categroy_image"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {selected === "General"
                    ? data?.category_name
                    : data?.vital_category_name || ""}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
              onClick={() => {
                setCategory(data);
              }}
            >
              Tests
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delCategory(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default CategoriesTable;
