import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { useAuth } from "../../context/useAuth";
import { getPackages } from '../../utils/packagesUtils/packagesUtils';
import { BsPlus } from 'react-icons/bs';
import PackagesTable from './PackagesTable';
import PackageModal from './PackageModal';

const PackagesMainSection = () => {
    const user = useAuth().authState.user;
    const [open, setOpen] = useState(false);
    const [editData,setEditData] = useState(null);
    const [packages, setPckages] = useState([]);
    const setPackagesFn = useCallback(() =>{
      getPackages().then(res => { setPckages(res.data.data) }).catch(err => console.log(err));
    }, []);
  useEffect(() => { setPackagesFn() }, [setPackagesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = packages?.filter((packageData) => {
      const nameMatch = packageData?.health_package_name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const categoryMatch = packageData?.general_category?.category_name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vitalOrganCategoryMatch = packageData?.vital_organ_category?.vital_category_name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || categoryMatch || vitalOrganCategoryMatch;
    });
    setFilteredData(filteredData);
  }, [packages, searchQuery]);
  return (
    <>
      {open && (
        <PackageModal
          setPackagesFn={setPackagesFn}
          user={user}
          setEditData={setEditData}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Packages</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name, Category, Test, Sub Category"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">Packages</div>
        <div className="flex gap-2">
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className=" text-white   text-sm rounded-md flex gap-2 p-2  items-center"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <BsPlus fontSize={18} />
            </span>
            <span className="flex gap-1">Package</span>
          </button>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <PackagesTable
          datas={filteredData}
          setEditData={setEditData}
          setOpen={setOpen}
          setPackagesFn={setPackagesFn}
        />
      ) : (
        <div className="flex w-full justify-center items-center mt-10">
          <NoDataFound data={"packages"} />
        </div>
      )}
    </>
  );
}

export default PackagesMainSection