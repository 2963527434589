import React, { useState } from 'react';
import { ImageInput, ShowImage, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { toast } from 'react-toastify';
import { addTube } from '../../utils/tubesUtils/tubesUtils';

const TubeModal = ({ setOpen, editData = null, setEditData, setDatasFn, }) => {
    const initailsState = {
        tube_name: editData?.tube_name || "",
    };
    const [tube_image, setTubeImage] = useState(editData?.tube_image || null);
    const [formData, handleFormData, clearForm] = useForm(initailsState);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.tube_name.trim().length) {
            setErr({ global: "Invalid Tube Name" });
            return;
        }
        if (!tube_image) {
            setErr({ global: "Please Select Image" });
            return;
        }
        setLoading(true);
        addTube(formData, tube_image, editData?._id)
            .then((res) => {
                setDatasFn();
                setEditData(null);
                setTubeImage(null);
                clearForm();
                setOpen(false);
                toast(`Tube ${editData ? `Edited` : `Added`}`, {
                    type: "success",
                    autoClose: 3000,
                });
            })
            .catch((err) => {
                console.log(err);
                toast(`Tube ${editData ? `Edit Failed` : `Add Failed`}`, {
                    type: "error",
                    autoClose: 3000,
                });
            })
            .finally(() => setLoading(false));
    }
    return (
        <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
            <div
                style={{
                    background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
                }}
                className="flex w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
            >
                <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
                    <span>{editData ? "Edit Tube" : "Add New Tube"}</span>
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            setOpen(false);
                            setEditData(null);
                        }}
                    >
                        <GrFormClose fontSize={16} />
                    </span>
                </div>
                <div className="flex w-full h-fit justify-between gap-5">
                    <TextInput
                        name="Tube"
                        formName={"tube_name"}
                        data={formData}
                        handleInput={handleFormData}
                        setError={setErr}
                    />
                </div>
                <div className="flex w-full h-fit justify-between gap-5">
                    <ShowImage image={tube_image} setImage={setTubeImage} />
                    <ImageInput
                        imageName={"Tube Image"}
                        setImage={setTubeImage}
                    />
                </div>
                {err.global && (
                    <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
                        {err.global}
                    </div>
                )}
                <div className="flex w-full h-fit justify-between gap-5">
                    <div className=" flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                            onClick={() => {
                                setOpen(false);
                                setEditData(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
                            disabled={loading}
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TubeModal;