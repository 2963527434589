import { axiosAuthorized } from "../../api/config"

export const getFaq = async () => {
  try {
    const response = await axiosAuthorized.get(`/labfaq/get_labfaq`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addFaq = async (lab_faq, id = null) => {
  try {
    const requestData = {
      lab_faq 
    };
    
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/labfaq/update_labfaq/${id}`
      : `/labfaq/add_labfaq`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteFaq = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labfaq/delete_labfaq/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}