import { axiosAuthorized } from "../../api/config";

export const getGeneralCategoriesCount = async () => {
  try {
    const response = await axiosAuthorized.get(`/countlabcategory`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getGeneralCategories = async () => {
  try {
    const repsonse = await axiosAuthorized.get(`/labcategory/get_labcategory`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}
export const addGeneralCategory = async (initialState, category_icon, categoryId = null) => {
  try {
    const reqestData = {
      ...initialState,
      category_icon
    }
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    const url = categoryId ? `/labcategory/update_labcategory/${categoryId}` : `/labcategory/add_labcategory`
    const repsonse = await axiosAuthorized[categoryId ? "put" : "post"](url, reqestData, requestConfig);
    return repsonse;
  } catch (error) {
    throw error;
  }
}

export const deleteGeneralCategory = async (categoryId) => {
  try {
    const repsonse = await axiosAuthorized.delete(`/labcategory/delete_labcategory/${categoryId}`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}