import { axiosAuthorized } from "../../api/config";

export const getVitalOrganCategoriesCount = async () => {
  try {
    const response = await axiosAuthorized.get(`/countlabcategory`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getVitalOrganCategories = async () => {
  try {
    const repsonse = await axiosAuthorized.get(`/vitalorgancategory/get_vitalorgancategory`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}
export const addVitalOrganCategory = async (initialState,  vital_category_icon, categoryId = null) => {
  try {
    const reqestData = {
      ...initialState,
      vital_category_icon
    }
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    const url = categoryId ? `/vitalorgancategory/update_vitalorgancategory/${categoryId}` : `/vitalorgancategory/add_vitalorgancategory`
    const repsonse = await axiosAuthorized[categoryId ? "put" : "post"](url, reqestData, requestConfig);
    return repsonse;
  } catch (error) {
    throw error;
  }
}

export const deleteVitalOrganCategory = async (categoryId) => {
  try {
    const repsonse = await axiosAuthorized.delete(`/vitalorgancategory/delete_vitalorgancategory/${categoryId}`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}