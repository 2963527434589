import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addFaq } from "../../utils/homeUtils/faqUtils";
import { useRef } from "react";
import JoditEditor, {Jodit} from "jodit-react";

const config = {
  readonly: false,
  placeholder: "Answer",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const FaqModal = ({ setOpen, setDatasFn, editData = null }) => {
  console.log(editData);
  const [err, setErr] = useState({ global: "" });
  const [faq, setFaqs] = useState(editData?.lab_faq || []);
  const [loading, setLoading] = useState(false);
  const [faqFormData, handleFaqFormData, clearFaqFormData] = useForm({
    faq_question: "",
  });
  const editor = useRef(null);
  const [answer, setAnswer] = useState(editData?.faq_answer || "<p></p>");
  const handleFaqData = (action, idx = 0) => {
    if (action === "add") {
      if (
        !faqFormData.faq_question.trim().length ||
        !answer.replace(/<[^>]*>/g, "").trim().length
      ) {
        return setErr({ global: "Please Enter Question & Answer " });
      } else {
        setFaqs([
          ...faq,
          {
            faq_question: faqFormData.faq_question,
            faq_answer: answer,
          },
        ]);
        setAnswer("<p></p>");
        clearFaqFormData();
      }
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!faq.length) return setErr({ global: "Add At Least One FAQ" });
    setErr({ global: "" });
    setLoading(true);
    addFaq(faq, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Faq ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Faq ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  max-w-lg w-full overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Faq"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <span>{item?.faq_question}</span>
                </div>
                <button
                  onClick={() => {
                    handleFaqData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer gap-5 ">
          <TextInput
            data={faqFormData}
            handleInput={handleFaqFormData}
            name={"Question"}
            formName={"faq_question"}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Answer"} className="label">
            {"Answer"} <span className="text-red-600">*</span>
          </label>
          <div className="bg-white text-black rounded-md">
            <JoditEditor
              ref={editor}
              value={answer}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setAnswer(
                  newContent.replace(
                    /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                    '<td style="border: 1px solid black;" >'
                  )
                );
              }}
            />
          </div>
        </div>
        <AddButton handleAdd={handleFaqData} />
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqModal;
