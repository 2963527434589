export const validateTestModalForm = (testFormData,tubeId, image, setErr) => {

    if (!testFormData.test_name.trim().length) {
        setErr({ global: "Invalid Test Name" });
        return false;
  }
  
  if (testFormData.service === "Select Service") {
    setErr({ global: "Please Select Service" });
    return false;
  }

  if (testFormData.gender === "Select Gender") {
    setErr({ global: "Please Select Gender" });
    return false;
  }

  if (!testFormData.time_required.trim().length) {
    setErr({ global: "Invalid Time Required" });
    return false;
  }

  if(!tubeId){
    setErr({ global: "Select Tube" });    
    return false;
  }

    if (!image) {
        setErr({ global: "Select Image" });
        return false;
    }

    return true;
};
