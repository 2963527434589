import { axiosAuthorized } from "../../api/config"

export const getHealthAndWellnessBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/healthbanner/get_healthbanner`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addHealthAndWellnessBanner = async (health_banner_image, id = null) => {
  try {
    const requestData = {
      health_banner_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/healthbanner/update_healthbanner/${id}`
      : `/healthbanner/add_healthbanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteHealthAndWellnessBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/healthbanner/delete_healthbanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}