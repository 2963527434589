import React, { useEffect, useMemo, useState } from 'react';
import { BiCheck } from "react-icons/bi";
import { updateStatus } from '../../../utils/appointmentUtils/appointmentUtils';
import { FileInput, ShowFile } from '../../Common/FormComponents';
import { toast } from "react-toastify";

const TrackerBar = ({ data, setAppointmentsFn, setOpenTracker, setEditData }) => {
    const steps = useMemo(() => ["Order-Placed","Sample-Testing","Test-Completed"], []);
    const [updatedStatus, setUpdatedStatus] = useState(null);
    const [currStatusIdx, setCurrStatusIdx] = useState(0);
    useEffect(() => {
      const  status = updatedStatus || data?.status
      setCurrStatusIdx(
        steps.findIndex((step) => step?.toLowerCase() === status?.toLowerCase())
      );
    }, [data, steps,updatedStatus]);
    const [selectedStatus, setSelectedStatus] = useState(updatedStatus || data?.status);
    const [file, setFile] = useState(data?.upload_lab_report || null);
    const updStatus = () => {
        updateStatus(selectedStatus?.toLowerCase(), file, data?._id)
          .then((res) => {
            setUpdatedStatus(res?.data?.data?.status);
            setAppointmentsFn();
              toast(`Test Status Updated`, {
                type: "success",
                autoClose: 3000,
              });
          })
          .catch((err) => {
            console.log(err); 
              toast(`Test Status Update Failed`, {
                type: "error",
                autoClose: 3000,
              });
          });
    };
  return (
    <div className="w-full mx-auto text-white">
      <div className="flex pb-1">
        <div className="flex-1"></div>

        <div className="flex-1">
          <div
            className={`w-10 h-10 ${
              currStatusIdx >= 0 ? "bg-green-400" : "border-2"
            } mx-auto rounded-full text-lg text-white flex items-center`}
          >
            <span className="text-black flex justify-center items-center w-full text-white">
              {currStatusIdx >= 0 ? <BiCheck fontSize={25} color="white" /> : 1}
            </span>
          </div>
        </div>

        <div className="w-1/3 align-center items-center align-middle content-center flex">
          <div className="w-full bg-gray-100  rounded items-center align-middle align-center flex-1">
            <div
              className={`bg-green-400 text-xs leading-none py-1 text-center text-grey-darkest rounded `}
              style={{ width: currStatusIdx >= 1 ? "100%" : "0%" }}
            ></div>
          </div>
        </div>

        <div className="flex-1">
          <div
            className={`w-10 h-10 ${
              currStatusIdx >= 1 ? "bg-green-400" : "border-2"
            } mx-auto rounded-full text-lg text-white flex items-center`}
          >
            <span className="text-black flex justify-center items-center w-full text-white">
              {currStatusIdx >= 1 ? <BiCheck fontSize={25} color="white" /> : 2}
            </span>
          </div>
        </div>

        <div className="w-1/3 align-center items-center align-middle content-center flex">
          <div className="w-full bg-gray-100  rounded items-center align-middle align-center flex-1">
            <div
              className={`bg-green-400 text-xs leading-none py-1 text-center text-grey-darkest rounded `}
              style={{ width: currStatusIdx >= 2 ? "100%" : "0%" }}
            ></div>
          </div>
        </div>

        <div className="flex-1">
          <div
            className={`w-10 h-10 ${
              currStatusIdx >= 2 ? "bg-green-400" : "border-2"
            } mx-auto rounded-full text-lg text-white flex items-center`}
          >
            <span className="text-black flex justify-center items-center w-full text-white">
              {currStatusIdx >= 2 ? <BiCheck fontSize={25} color="white" /> : 3}
            </span>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>

      <div className="flex text-xs content-center text-center justify-center">
        {steps?.map((step, index) => (
          <div key={index} className="w-1/2">
            {step}
          </div>
        ))}
      </div>
      <div className="flex  w-full  gap-4 flex-col mt-5">
        <select
          className="appearance-none w-full bg-white border text-black  text-[14px] rounded py-2 px-4 outline-none shadow-md"
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
        >
          {steps?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowFile file={file} setFile={setFile} />
          <FileInput fileName={"Lab Document"} setFile={setFile} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <button
            onClick={() => {
              setOpenTracker(false);
              setEditData(null);
            }}
            className=" w-full py-1 px-2  capitalize h-full flex justify-center items-center border rounded-md border-white text-white"
          >
            cancel
          </button>
          <button
            onClick={() => {
              updStatus();
            }}
            className=" w-full py-1 px-2  capitalize h-full flex justify-center items-center rounded-md bg-primary text-white"
          >
            update
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrackerBar