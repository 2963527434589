import React, { useEffect, useMemo, useState } from "react";
import { NoDataFound, Table } from "../Common";
import { getTestsByGeneralCategory, getTestsByVitalOrganCategory } from "../../utils/testsUtils/testsUtils";

const TestsList = ({ selectedCategory, selected }) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    if (selected === "General") {
      getTestsByGeneralCategory(selectedCategory?._id).then(res => {
        setDatas(res?.data?.data);
      }).catch(err => console.error(err));
    } else {
      getTestsByVitalOrganCategory(selectedCategory?._id)
        .then((res) => {
          setDatas(res?.data?.data);
        })
        .catch((err) => console.error(err));
    }
  }, [selected, selectedCategory?._id]);
  const headings = useMemo(
    () => ["Test", "T-ID", "Gender", "Price", "Discount", "Total"],
    []
  );
  return datas?.length > 0 ? (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={data?.test_pic}
                  alt="test_image"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {data?.test_name}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.test_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.gender}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.price}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.discount}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.total_price}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  ) : (
    <div className="flex w-full justify-center items-center mt-10">
      <NoDataFound data={"tests"} />
    </div>
  );
};

export default TestsList;
