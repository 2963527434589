import React from "react";
import CategoriesMainSection from "../../components/CategoriesComponents/CategoriesMainSection";
const Categories = () => {
  return (
    <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
      <CategoriesMainSection/>
    </div>
  );
};

export default Categories;
