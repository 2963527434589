import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import { BsArrowLeftShort, BsPlus } from "react-icons/bs";
import { useAuth } from "../../context/useAuth";
import CategoriesTable from "./CategoriesTable";
import GeneralCategoryModal from "./GeneralCategoryModal";
import { getGeneralCategories } from "../../utils/categoriesUtils/generalCategoryUtils";
import VitalOrganCategoryModal from "./VitalOrganCategoryModel";
import { getVitalOrganCategories } from "../../utils/categoriesUtils/vitalOrganCategoryUtils";
import TestsList from "./TestsList";

const CategoriesMainSection = () => {
  const user = useAuth().authState.user;
  const [selected, setSelected] = useState("General");
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [categories, setCategories] = useState([]);
  const setCategoriesFn = useCallback(() => {
    if (selected === "General") {
      getGeneralCategories()
        .then((res) => {
          setCategories(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      getVitalOrganCategories()
        .then((res) => {
          setCategories(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [selected]);
  useEffect(() => {
    setCategoriesFn();
  }, [setCategoriesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = categories?.filter((category) => {
      let nameMatch = true;
      if (selected === "General") {
        nameMatch = category?.category_name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
      } else {
        nameMatch = category?.vital_category_name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
      }
      return nameMatch;
    });
    setFilteredData(filteredData);
  }, [categories, searchQuery, selected]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <>
      {open &&
        (selected === "General" ? (
          <GeneralCategoryModal
            user={user}
            setEditData={setEditData}
            setOpen={setOpen}
            setCategoriesFn={setCategoriesFn}
            editData={editData}
          />
        ) : (
          <VitalOrganCategoryModal
            user={user}
            setEditData={setEditData}
            setOpen={setOpen}
            setCategoriesFn={setCategoriesFn}
            editData={editData}
          />
        ))}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Categories</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-between items-center">
        <div className="flex w-full items-center gap-3">
          <div className="flex gap-3">
            <button
              onClick={() => { setSelected("General"); setSelectedCategory(null); }}
              className={`flex px-3 border border-primary py-1 rounded-md ${
                selected === "General"
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              General
            </button>
            <button
              onClick={() => { setSelected("Vital-Organ"); setSelectedCategory(null); }}
              className={`flex px-3 border border-primary py-1 rounded-md ${
                selected === "Vital-Organ"
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Vital Organ
            </button>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className=" text-white   text-sm rounded-md flex gap-2 p-2 items-center flex-shrink-0"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <BsPlus fontSize={18} />
            </span>
            <span className="flex gap-1 ">{`Category`}</span>
          </button>
        </div>
      </div>
      {selectedCategory && (
        <button
          className={`w-fit flex text-sm rounded-md gap-2 p-2 text-primary items-center`}
          onClick={() => setSelectedCategory(null)}
        >
          <span>
            <BsArrowLeftShort />
          </span>
          <span>Back</span>
        </button>
      )}
      {selectedCategory ? (
        <TestsList selected={selected} selectedCategory={selectedCategory} />
      ) : filteredData?.length > 0 ? (
        <CategoriesTable
          selected={selected}
          datas={filteredData}
          setEditData={setEditData}
          setOpen={setOpen}
          setCategoriesFn={setCategoriesFn}
          setCategory={setSelectedCategory}
        />
      ) : (
        <div className="flex w-full justify-center items-center mt-10">
          <NoDataFound data={"categories"} />
        </div>
      )}
    </>
  );
};

export default CategoriesMainSection;
