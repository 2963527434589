import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Table } from "../Common";
import { TbUserEdit } from "react-icons/tb";
import { deleteTube } from "../../utils/tubesUtils/tubesUtils";
const TubesTable = ({ datas, setOpen, setEditData, setTubesFn }) => {
    const headings = useMemo(
        () => [
            "Tube",
            "Edit",
            "Delete"
        ],
        []
    );
    const delTube = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete ?");
        if (!confirmed) return;
        deleteTube(id).then(res => {
            setTubesFn();
        }).catch(err => console.log(err));
    };
    return (
        <Table headings={headings}>
            {datas?.map((data, index) => (
                <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                                <img
                                    className="h-10 w-10 rounded-full object-cover"
                                    src={data?.tube_image || ""
                                    }
                                    alt="tube_image"
                                />
                            </div>
                            <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                    {data?.tube_name || ""}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                        <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                                setEditData(data);
                                setOpen(true);
                            }}
                        >
                            <TbUserEdit />
                        </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                        <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                                delTube(data?._id);
                            }}
                        >
                            <MdOutlineDeleteOutline color="red" />
                        </div>
                    </td>
                </tr>
            ))}
        </Table>
    );
};

export default TubesTable;
