import { axiosAuthorized } from "../../api/config"

export const getCallUsBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/labcallus/get_labcallus`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCallUsBanner = async (initialState, callus_image, id = null) => {
  try {
    const requestData = {
      ...initialState,
      callus_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/labcallus/update_labcallus/${id}`
      : `/labcallus/add_labcallus`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCallUsBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labcallus/delete_labcallus/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}