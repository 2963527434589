import React from 'react'
import MainBanner from './MainBanner';
import Faq from './Faq';
import NeedHelpWithBooking from './NeedHelpWithBooking';
import CallUsBanners from './CallUsBanner';
import HealthAndWellnessBanners from './HealthAndWellnessBanners';
import XRayBanners from './XRayBanners';
import CertificatesBanners from "./CertificatesBanners";
import BookLabTestWithUs from './BookLabTestWithUs';
// import YourTrustedLabPartners from './YourTrustedLabPartners';
// import LabTestBookInSimpleSteps from './LabTestBookInSimpleSteps';

const HomeMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex w-full h-fit text-xl font-semibold">Home Page</div>
        <div className="flex flex-col w-full mt-5 gap-5">
          <MainBanner />
          <NeedHelpWithBooking />
          <CallUsBanners />
          <HealthAndWellnessBanners />
          <XRayBanners />
          <CertificatesBanners />
          <Faq />
          <BookLabTestWithUs />
          {/* <YourTrustedLabPartners />
          <LabTestBookInSimpleSteps/> */}
        </div>
      </div>
    </>
  );
}

export default HomeMainSection